import { get } from 'lodash'

import { fetchAPI, fetchAPIv2 } from '@/lib/api'

export function getPolicies({ userData }) {
  return fetchAPI({
    method: 'get',
    path: '/policies',
    userData,
  }).then((res) => {
    return get(res, 'data.policies', [])
  })
}

export function getPolicy({ token, userData }) {
  return fetchAPI({
    method: 'get',
    path: `/policies/${token}`,
    userData,
  }).then((res) => {
    return get(res, 'data.policy', {})
  })
}

export function getQRCode(params) {
  return fetchAPI({
    method: 'get',
    path: '/gbpay/qrcode',
    params,
  }).then((res) => {
    return get(res, 'data', null)
  })
}

export function deletePolicy({ token, userData }) {
  return fetchAPI({
    method: 'delete',
    path: `/policies/${token}`,
    userData,
  }).then((res) => {
    return get(res, 'data', null)
  })
}

export function getPublicPolicy({ token, lang }) {
  return fetchAPI({
    method: 'get',
    path: `/policies/${token}/public`,
    params: {
      lang,
    },
  }).then((res) => {
    return get(res, 'data', {})
  })
}

export const confirmPolicy = (params = {}) => {
  const { token, userData } = params

  return fetchAPI({
    path: `/policies/${token}/confirm`,
    method: 'put',
    userData,
  }).then((res) => {
    return get(res, 'data')
  })
}

export const resendConfirmation = ({ email }) => {
  return fetchAPI({
    path: `/auth/confirmation`,
    method: 'post',
    data: { email },
  }).then((res) => {
    return get(res, 'data')
  })
}

export const getInfo = (userData) => {
  return fetchAPI({
    path: `/me`,
    method: 'get',
    userData,
  }).then((res) => {
    return get(res, 'data.user')
  })
}

export const displaySurvey = () => {
  return fetchAPIv2({
    path: '/users/survey',
    method: 'put',
  })
}
