import React from 'react'

import { redirectPage } from '@/lib/helpers'

import { AuthLayout } from '@/features/layouts'

export const withAuthLayout = (PageComponent) => {
  function EnhancedPageComponent(props) {
    return (
      <AuthLayout>
        <PageComponent {...props} />
      </AuthLayout>
    )
  }

  EnhancedPageComponent.getInitialProps = async function (ctx) {
    let pageProps = {}

    const { isAuth, query } = ctx

    if (isAuth) {
      redirectPage(
        {
          path: query.redirect_url
            ? String(query.redirect_url)
            : '/dashboard/policies',
        },
        ctx,
      )
    }

    if (typeof PageComponent.getInitialProps === 'function') {
      pageProps = await PageComponent.getInitialProps(ctx)
    }

    return pageProps
  }

  return EnhancedPageComponent
}
