import { Fragment } from 'react'
import { NextSeo } from 'next-seo'

import { LoginLayout } from '@/features/auth/components'
import { withAuthPage } from '../components/withAuthPage'

function Login() {
  return (
    <Fragment>
      <NextSeo title="Login" />

      <LoginLayout />
    </Fragment>
  )
}

export default withAuthPage()(Login)
